import { createContext, ElementType, useContext, useState } from "react";
import { CourseMember } from "../../assets/type/backend.type";
import { Language } from "../../assets/type/frontend.type";
import { UserSetting, UserSettingContextProps } from "./type";

const UserSettingContext = createContext<UserSettingContextProps>({
	defaultLanguage: Language.en_US,
	currentUserSetting: {
		language: Language.en_US,
		courseMember: null,
	},
	setCurrentUserSetting: () => {},
	setLanguage: () => {},
	setCourseMember: () => {},
});
export const useUserSettingContext = () => useContext(UserSettingContext);
export const WithUserSettingContext = (Component: ElementType) => {
	return function WithUserSettingContext(props: any) {
		const [currentUserSetting, setCurrentUserSetting] = useState<UserSetting>({
			language: Language.en_US,
			courseMember: null,
		});
		const setLanguage = (language: Language) => {
			let newSetting = { ...currentUserSetting };
			newSetting.language = language;
			setCurrentUserSetting(newSetting);
		};
		const setCourseMember = (course: CourseMember | null) => {
			let newSetting = { ...currentUserSetting };
			newSetting.courseMember = course;
			setCurrentUserSetting(newSetting);
		};
		const defaultContextValue: UserSettingContextProps = {
			defaultLanguage: Language.en_US,
			currentUserSetting: currentUserSetting,
			setCurrentUserSetting,
			setLanguage,
			setCourseMember: setCourseMember,
		};
		return (
			<UserSettingContext.Provider value={defaultContextValue}>
				<Component {...props} />
			</UserSettingContext.Provider>
		);
	};
};
