import { gql } from "@apollo/client"

export const GET_COURSES_BY_FILTER_QUERY = gql`
	query getCoursesByFilter($courseFilterInput: CourseFilterInput) {
		coursesGet(courseFilterInput: $courseFilterInput) {
			isSuccess
			code
			errorMessage
			data {
				id
				name
				subject
				questionBankId
			}
		}
	}
`
