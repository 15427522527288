import {
	FButton,
	FNoData,
	FScrollable,
	FText,
} from "@fantaskticedtechlimited/fui-complib"
import { FButtonTypes } from "@fantaskticedtechlimited/fui-complib/lib/esm/FButton/types"
import { FFontTypes } from "@fantaskticedtechlimited/fui-fontlib"
import React, { Fragment, useEffect, useState } from "react"
import useCourseAPIService from "../../../apiService/course"
import { Homework } from "../../../assets/type/backend.type"
import { useUserSettingContext } from "../../../contexts/userSettingContext"
import FScrollableContentWrapper from "../../../layout/FScrollableContentWrapper"
import PageContainer from "../../../layout/PageContainer"
import { useLoadingPageWrapperContext } from "../../../layout/WithLoadingPageWrapper"
import HomeworkList from "../components/homework/HomeworkList"
import * as styles from "./styles"
function Homeworks() {
	const { setLoadingStart, setLoadingEnd } = useLoadingPageWrapperContext()
	const courseAPI = useCourseAPIService()
	const {
		currentUserSetting: { courseMember },
	} = useUserSettingContext()
	const [homeworks, setHomeworks] = useState<Homework[]>([])

	const handleGetHomeworksByCourseId = async (
		courseId: string,
		withRefresh?: boolean,
	) => {
		withRefresh && setLoadingStart()
		try {
			const homeworksResult = await courseAPI.getHomeworksByFilter({
				homeworkFilterInput: {
					courseId,
				},
			})
			const { homeworksGet } = homeworksResult.data
			setHomeworks(homeworksGet.isSuccess ? homeworksGet.data || [] : [])
		} catch (error) {
			setHomeworks([])
		}
		withRefresh && setLoadingEnd()
	}

	useEffect(() => {
		if (courseMember) {
			handleGetHomeworksByCourseId(courseMember.courseId, true)
		}
	}, [courseMember])

	return (
		<PageContainer
			header={
				<div className={styles.homeworks_toolbarContainer}>
					{/* Toolbar */}
					{/* Title */}
					<FText
						style={{
							flexGrow: 1,
						}}
						font={FFontTypes.Title()}
					>
						My Homeworks
					</FText>

					{/* New Folder button */}
					<FButton
						type={FButtonTypes.PRIMARY}
						onClick={() => {
							alert("New Homework")
						}}
						label="New Homework"
					/>

					{/* refresh button */}
					<FButton
						type={FButtonTypes.PRIMARY}
						onClick={async () => {
							courseMember &&
								(await handleGetHomeworksByCourseId(
									courseMember.courseId,
									true,
								))
						}}
						label="Refresh"
					/>
				</div>
			}
			content={
				<Fragment>
					<div>Total {homeworks.length} homeworks </div>
					<FScrollable>
						<FScrollableContentWrapper>
							{homeworks.length === 0 ? (
								<FNoData description="No homeworks" />
							) : (
								<Fragment>
									<HomeworkList
										homeworks={homeworks}
										onRefresh={() => {
											courseMember &&
												handleGetHomeworksByCourseId(
													courseMember.courseId,
													false,
												)
										}}
									/>
								</Fragment>
							)}
						</FScrollableContentWrapper>
					</FScrollable>
				</Fragment>
			}
		/>
	)
}

export default Homeworks
