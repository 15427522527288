import { FSelector } from "@fantaskticedtechlimited/fui-complib"
import { useCallback } from "react"
import useCourseAPIService from "../../apiService/course"
import useQBAPIService from "../../apiService/questionBank"
import { CourseMember } from "../../assets/type/backend.type"
import { useUserSettingContext } from "../../contexts/userSettingContext"
import useMount from "../../utils/useMount"
import { useStateSafe } from "../../utils/useStateSafe"
import { CourseSelectDropDownProps } from "./type"

function CourseMemberSelectDropDown(props: CourseSelectDropDownProps) {
	const CourseAPI = useCourseAPIService()
	const { currentUserSetting, setCourseMember } = useUserSettingContext()
	const [courseMemberList, setCourseMemberList] = useStateSafe<
		CourseMember[]
	>([])

	const handleGetMyCourses = useCallback(async () => {
		try {
			const MyCoursesResult = await CourseAPI.getMyCourses({})
			const {isSuccess, data} = MyCoursesResult.data.myCourses
			if (isSuccess) {
				let dataList = data || []
				setCourseMemberList(dataList)
				if(dataList.length > 0){
					setCourseMember(dataList[0])
				}
			}
		} catch (error) {}
	}, [CourseAPI, setCourseMemberList])

	const handleSelectCourseMember = (value: CourseMember | null) =>
		setCourseMember(value)

	useMount(() => {
		handleGetMyCourses()
	})

	return (
		<FSelector
			label={`Course`}
			showLabelOnly
			options={courseMemberList || []}
			onSelect={handleSelectCourseMember}
			selectedOptions={currentUserSetting.courseMember}
			renderOptionNameOnly={(data) => data.course!.name}
			onClear={() => handleSelectCourseMember(null)}
		/>
	)
}

export default CourseMemberSelectDropDown
