import { FColorTypes } from "@fantaskticedtechlimited/fui-colorlib";
import { FFontTypes } from "@fantaskticedtechlimited/fui-fontlib";
import { style } from "typestyle";

export const loginBoxContainer = style({
	width: "400px",
	background: "white",
	boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
	padding: "32px 32px 32px 32px",
	boxSizing: "border-box",
	$nest: {
		"@media screen and (max-width: 719px)": {
			height: "100vh",
			marginTop: "0px",
			boxShadow: "none",
		},
		body: {
			backgroundColor: "white !important",
		},
	},

	// added code for flex
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
	rowGap: ".5rem",
});

export const loginBoxLogo = style({
	width: "128px",
	height: "48px",
});

export const loginButton = style({
	$nest: {
		"&:hover": {
			opacity: 0.7,
		},
	},

	// with flex
	alignSelf: "flex-end",
});

export const ssoButtonBox = style({
	width: "336px",
	border: "2px solid #E2E2E2 !important",
	borderRadius: "8px",
	boxSizing: "border-box",
	$nest: {
		"&:hover": {
			backgroundColor: "whitesmoke !important",
			cursor: "pointer",
		},
	},
	// with flex
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
});

export const ssoButtonLogo = style({
	width: "24px",
	height: "24px",
});

export const ssoButtonLabel = style({
	width: "100%",
	textAlign: "center",
	color: FColorTypes.PRIMARY_BLACK + " !important",
	font: FFontTypes.Large_Text(),
});
