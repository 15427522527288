import { TeacherRowProps } from "./type";
import { useLocaleString } from "../../../../../utils/useLocaleString";
import { useNavigate } from "react-router";
import { FColorTypes } from "@fantaskticedtechlimited/fui-colorlib";
import { FSwitchButton, FText } from "@fantaskticedtechlimited/fui-complib";
import { FIcon, FIconNames } from "@fantaskticedtechlimited/fui-iconlib";
import FListCard from "../../../../../Components/FListCard";
import { Fragment } from "react";
import { ImageAsset } from "../../../../../assets/images";

function TeacherRow(props: TeacherRowProps) {
	const { teacher } = props;
	const ls = useLocaleString();

	return (
		<FListCard
			index={props.index}
			isDisableToolBar={props.isDisableToolbar}
			content={
				<Fragment>
					{/* data in middle top */}
					<FText maxRows={2}>{teacher.userEmail}</FText>
					{/* data in middle bottom */}
					{/* status */}
					<FText color={FColorTypes.PRIMARY_GREY} maxRows={1}>
						{teacher.status}
					</FText>
				</Fragment>
			}
			toolBar={
				<Fragment>
					{/* Button on Right side */}

					{/* More Action button */}
					<FIcon
						name={FIconNames.MORE}
						size="large"
						strokeColor={FColorTypes.PRIMARY_LIGHT}
						onClick={() => {
							props.onMoreActionButtonClick && props.onMoreActionButtonClick();
						}}
					/>
				</Fragment>
			}
		/>
	);
}

export default TeacherRow;
