import { style } from "typestyle"

export const toolbarContainer = style({ 
    display: "flex",
    flexDirection: "row",
    justifyContent:"space-between",
    alignItems:"center",
    columnGap:"0.75rem",
    minHeight:"3rem"
})
