import { FColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import {style} from 'typestyle'
import { PageContainerProps } from './types'

export const usePageContainer = (props:PageContainerProps) => {
    // const { currentUserSetting} = useUserSettingContext()
    // console.log("currentUserSetting",currentUserSetting)
    return style({
    
        width:"100%",
        maxWidth: props.maxWidth ?? "1280px",
        height: "100%",
        margin: "0 auto",
        backgroundColor: FColorTypes.PRIMARY_WHITE,
        border: "0.075rem solid " + FColorTypes.PRIMARY_GREY,
        // borderRadius: "0.75rem",
        boxShadow: "0rem 0rem 0.5rem rgba(72, 63, 226, 0.16)",
        boxSizing: "border-box",
        padding: "12px",
        
        display: "flex",
        flexDirection: "column",
        rowGap: "24px",
        position: "relative",
    
        overflow:"hidden"
    })
}

export const divider = style({
    height: ".1rem",
    width: "100%",
    background: "#E2E2E2"
})
