import { Outlet } from 'react-router-dom';
import * as styles from './styles'
function Login() {

	return (
		<div className={styles.loginPageContainer}>
			<Outlet />
		</div>
	);
}

export default Login;
