import { gql } from "@apollo/client"

export const GET_COURSE_DETAIL_BY_ID_QUERY = gql`
	query getCourseDetailById($courseId: String!) {
		courseGet(id: $courseId) {
			isSuccess
			errorMessage
			code
			data {
				id
				name
				subject
				students {
					id
					userId
					userEmail
					status
				}
				teachers {
					id
					userId
					userEmail
					status
				}
				homeworks {
					id
					name
					startDate
					endDate
				}
			}
		}
	}
`
