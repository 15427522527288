import {
	FButton,
	FNoData,
	FScrollable,
  FText,
} from "@fantaskticedtechlimited/fui-complib"
import { FButtonTypes } from "@fantaskticedtechlimited/fui-complib/lib/esm/FButton/types"
import { FFontTypes } from "@fantaskticedtechlimited/fui-fontlib"
import { Fragment, useCallback, useEffect, useState } from "react"
import useCourseAPIService from "../../../apiService/course"
import { CourseMember } from "../../../assets/type/backend.type"
import { useUserSettingContext } from "../../../contexts/userSettingContext"
import FScrollableContentWrapper from "../../../layout/FScrollableContentWrapper"
import PageContainer from "../../../layout/PageContainer"
import { useLoadingPageWrapperContext } from "../../../layout/WithLoadingPageWrapper"
import TeacherList from "../components/teacher/TeacherList"
import * as styles from "./styles"

function Students() {
	const { setLoadingStart, setLoadingEnd } = useLoadingPageWrapperContext()
	const { currentUserSetting } = useUserSettingContext()
	const CourseAPI = useCourseAPIService()
	const [students, setStudents] = useState<CourseMember[]>([])
	const handleGetCourseStudentsByCourseId = useCallback(
		async (courseId: string, withLoading?: boolean) => {
			withLoading && setLoadingStart()
			try {
				const courseDetailResult = await CourseAPI.getCourseDetailById({
					courseId,
				})
				const { isSuccess, data } = courseDetailResult.data.courseGet
				setStudents(isSuccess ? data?.students || [] : [])
			} catch (error) {
				setStudents([])
			}
			withLoading && setLoadingEnd()
		},
		[CourseAPI, setStudents],
	)
	const handleRefresh = async (withLoading?: boolean) => {
		if (currentUserSetting.courseMember !== null) {
			await handleGetCourseStudentsByCourseId(
				currentUserSetting.courseMember.course?.id!,
				withLoading,
			)
		}
	}

	useEffect(() => {
		if (currentUserSetting.courseMember) {
			handleGetCourseStudentsByCourseId(
				currentUserSetting.courseMember.course?.id!,
			)
		}
	}, [currentUserSetting.courseMember])

	return (
		<PageContainer
			style={{
				rowGap: 0,
			}}
			header={
				<div className={styles.headerContainer}>
          {/* Title */}
					<FText
						style={{
							flexGrow: 1,
						}}
						font={FFontTypes.Title()}
					>
						Course Students
					</FText>

          {/* Tools */}
					{/* refresh button */}
					<FButton
						type={FButtonTypes.PRIMARY}
						onClick={async () => await handleRefresh(true)}
						label="Refresh"
					/>
				</div>
			}
			content={
				<FScrollable>
					<FScrollableContentWrapper>
						{students.length === 0 ? (
							<FNoData />
						) : (
							<Fragment>
								<TeacherList
									teachers={students || []}
									onRefresh={async () =>
										await handleRefresh(false)
									}
								/>
							</Fragment>
						)}
					</FScrollableContentWrapper>
				</FScrollable>
			}
		/>
	)
}

export default Students
