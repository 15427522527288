import {
	FText,
	FInputField,
	FButton,
} from "@fantaskticedtechlimited/fui-complib";
import { FFontTypes } from "@fantaskticedtechlimited/fui-fontlib";
import { getAuth, indexedDBLocalPersistence } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import firebaseAppWithConfig from "../../../config/firebaseConfig";
import useFirebaseAuthService from "../../../utils/useFirebaseAuthService";
import * as styles from "./styles";
import { FButtonTypes } from "@fantaskticedtechlimited/fui-complib/lib/esm/FButton/types";
import { ImageAsset } from "../../../assets/images";

function MagicLink() {
	const firebaseAuthService = useFirebaseAuthService();
	const navigate = useNavigate();

	const [email, setEmail] = useState("");
	const handleSendEmailMagicLink = async (_email: string) => {
		try {
			let auth = getAuth(firebaseAppWithConfig);
			auth.setPersistence(indexedDBLocalPersistence)
			await firebaseAuthService.handleSendSignInLinkToEmail(
				auth,
				_email,
				"/verifyEmailMagicLink"
			);
			alert(`Email Magic Link Sent to ${_email}`);
			navigate("/sentMagicLink", { replace: true });
		} catch (error) {
			alert("Failed to send Email Magic Link ");
		}
	};

	return (
		<div className={styles.loginBoxContainer}>
			<img
				src={ImageAsset.backBtn}
				className={styles.backButton}
				onClick={() => navigate("/login", { replace: true })}
				alt="backBtn"
			/>
			<FText font={FFontTypes.Title()}>Magic Link</FText>

			<FInputField
				inputValue={email}
				renderInputValue={(v) => setEmail(v)}
				label="Continue with Email Magic Link"
			/>
			<FButton
				className={styles.loginButton}
				type={FButtonTypes.PRIMARY}
				label="Continue"
				onClick={() => handleSendEmailMagicLink(email)}
			/>
		</div>
	);
}

export default MagicLink;
