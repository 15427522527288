import { FNoData,FText } from "@fantaskticedtechlimited/fui-complib"
import { FFontTypes } from "@fantaskticedtechlimited/fui-fontlib"
import React, { Fragment, useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import useCourseAPIService from "../../../apiService/course"
import { Course } from "../../../assets/type/backend.type"
import { useUserSettingContext } from "../../../contexts/userSettingContext"
import PageContainer from "../../../layout/PageContainer"
import {
	useLoadingPageWrapperContext,
	WithLoadingPageWrapper,
} from "../../../layout/WithLoadingPageWrapper"
import * as styles from "./styles"

function CourseHome() {
	const navigate = useNavigate()
	const { setLoadingStart, setLoadingEnd } = useLoadingPageWrapperContext()
	const CourseAPI = useCourseAPIService()
	const { currentUserSetting } = useUserSettingContext()
	const [course, setCourse] = useState<Course | null>(null)

	const handleGetCourseDetailById = useCallback(
		async (courseId: string, withLoading?: boolean) => {
			withLoading && setLoadingStart()
			try {
				const courseDetailResult = await CourseAPI.getCourseDetailById({
					courseId,
				})
				if (courseDetailResult.data.courseGet.isSuccess) {
					setCourse(courseDetailResult.data.courseGet.data || null)
				} else {
					setCourse(null)
				}
			} catch (error) {
				setCourse(null)
			}
			withLoading && setLoadingEnd()
		},
		[CourseAPI, setCourse],
	)

	useEffect(() => {
		if (currentUserSetting.courseMember) {
			handleGetCourseDetailById(
				currentUserSetting.courseMember.course?.id!,
			)
		}
	}, [currentUserSetting.courseMember])

	return (
		<PageContainer
			header={
				<div className={styles.headerContainer}>
					{/* Toolbar */}
					{/* Title */}
					<FText
						style={{
							flexGrow: 1,
						}}
						font={FFontTypes.Title()}
					>
						{course?.name}
					</FText>
				</div>
			}
			content={
				course === null ? (
					<FNoData />
				) : (
					<Fragment>
						<FText>{`Name: ${course.name}`}</FText>
						<FText>{`Subject: ${course.subject}`}</FText>
						<FText>{`Homeworks: Total ${course.homeworks.length} homeworks`}</FText>
						<FText>{`Students: Total ${course.students.length} students`}</FText>
						<FText>{`Teachers: Total ${course.teachers.length} teachers`}</FText>
					</Fragment>
				)
			}
		/>
	)
}

export default WithLoadingPageWrapper(CourseHome)
