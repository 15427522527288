import { FColorTypes } from "@fantaskticedtechlimited/fui-colorlib";
import { useFUITheme } from "@fantaskticedtechlimited/fui-complib/lib/esm/FThemeContext";
import { style } from "typestyle";

export const MenuListDiv = (isAutoExpand: boolean) =>
	style({
		display: "flex",
		flexDirection: "column",
		flexGrow: isAutoExpand ? 1 : 0,
		justifyContent: "center",
		rowGap: "0.25rem",
	});

export const SubMenuList = (isIncluded: boolean, depth: number) =>
	style({
		paddingLeft: `${0.75 * depth}rem`,
		display: isIncluded || depth === 0 ? "visible" : "none",
	});

export const LinkButtonDiv = (isSelected: boolean, isFooter: boolean) =>
	style({
		width: isFooter ? "fit-content" : "100%",
		padding: isFooter ? 0 : undefined,
		backgroundColor:
			isSelected && !isFooter
				? FColorTypes.PRIMARY_WHITE
				: "transparent  !important",
		borderRadius: isFooter ? 0 : undefined,
		borderBottom:
			isFooter && isSelected
				? "0.125rem solid " + FColorTypes.PRIMARY_WHITE
				: "0.125rem solid transparent",
		$nest: {
			"&:hover": {
				backgroundColor: !isFooter
					? isSelected
						? undefined
						: "rgba(255, 255, 255, 0.24) !important"
					: undefined,
				borderBottom:
					isFooter && !isSelected
						? "0.125rem solid " + FColorTypes.PRIMARY_WHITE + " !important"
						: undefined,
			},
		},
	});

export const useMenuButtonStyle = (isSelected: boolean) => {
	const { theme } = useFUITheme();
	return style({
		$nest: {
			"&:hover": {
				// backgroundColor: "#8095ff",
				backgroundColor: isSelected
					? `${theme.mainThemeColor}`
					: theme.subThemeColor,
			},
		},
		padding: "8px !important",
	});
};

export const labelButton = style({
	fontSize: "14px !important",
	fontFamily: "Montserrat !important",
	fontWeight: 500,
});
