import { useEffect } from 'react'

const useMount = (cb:Function) =>{
    useEffect(() => {
        cb()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
} 


export default useMount