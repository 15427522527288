"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@apollo/client/core");
var TimeoutError_1 = require("./TimeoutError");
var DEFAULT_TIMEOUT = 15000;
/**
 * Aborts the request if the timeout expires before the response is received.
 */
var TimeoutLink = /** @class */ (function (_super) {
    __extends(TimeoutLink, _super);
    function TimeoutLink(timeout, statusCode) {
        var _this = _super.call(this) || this;
        _this.timeout = timeout || DEFAULT_TIMEOUT;
        _this.statusCode = statusCode;
        return _this;
    }
    TimeoutLink.prototype.request = function (operation, forward) {
        var _this = this;
        var controller;
        // override timeout from query context
        var requestTimeout = operation.getContext().timeout || this.timeout;
        // add abort controller and signal object to fetchOptions if they don't already exist
        if (typeof AbortController !== 'undefined') {
            var context = operation.getContext();
            var fetchOptions = context.fetchOptions || {};
            controller = fetchOptions.controller || new AbortController();
            fetchOptions = __assign({}, fetchOptions, { controller: controller, signal: controller.signal });
            operation.setContext({ fetchOptions: fetchOptions });
        }
        var chainObservable = forward(operation); // observable for remaining link chain
        var operationType = operation.query.definitions.find(function (def) { return def.kind === 'OperationDefinition'; }).operation;
        if (requestTimeout <= 0 || operationType === 'subscription') {
            return chainObservable; // skip this link if timeout is zero or it's a subscription request
        }
        // create local observable with timeout functionality (unsubscibe from chain observable and
        // return an error if the timeout expires before chain observable resolves)
        var localObservable = new core_1.Observable(function (observer) {
            var timer;
            // listen to chainObservable for result and pass to localObservable if received before timeout
            var subscription = chainObservable.subscribe(function (result) {
                clearTimeout(timer);
                observer.next(result);
                observer.complete();
            }, function (error) {
                clearTimeout(timer);
                observer.error(error);
                observer.complete();
            });
            // if timeout expires before observable completes, abort call, unsubscribe, and return error
            timer = setTimeout(function () {
                if (controller) {
                    controller.abort(); // abort fetch operation
                    // if the AbortController in the operation context is one we created,
                    // it's now "used up", so we need to remove it to avoid blocking any
                    // future retry of the operation.
                    var context = operation.getContext();
                    var fetchOptions = context.fetchOptions || {};
                    if (fetchOptions.controller === controller && fetchOptions.signal === controller.signal) {
                        fetchOptions = __assign({}, fetchOptions, { controller: null, signal: null });
                        operation.setContext({ fetchOptions: fetchOptions });
                    }
                }
                observer.error(new TimeoutError_1.default('Timeout exceeded', requestTimeout, _this.statusCode));
                subscription.unsubscribe();
            }, requestTimeout);
            var ctxRef = operation.getContext().timeoutRef;
            if (ctxRef) {
                ctxRef({
                    unsubscribe: function () {
                        clearTimeout(timer);
                        subscription.unsubscribe();
                    }
                });
            }
            // this function is called when a client unsubscribes from localObservable
            return function () {
                clearTimeout(timer);
                subscription.unsubscribe();
            };
        });
        return localObservable;
    };
    return TimeoutLink;
}(core_1.ApolloLink));
exports.default = TimeoutLink;
