import { FColorTypes } from "@fantaskticedtechlimited/fui-colorlib";
import { useFUITheme } from "@fantaskticedtechlimited/fui-complib/lib/esm/FThemeContext";
import { style } from "typestyle";
import { FListCardProps } from "./types";

export const useRowContainer = (props: FListCardProps) => {
	const { theme } = useFUITheme();
	const localTheme = props.theme ?? theme;
	return style({
		boxSizing: "border-box",
		padding: "0.75rem 0.5rem",
		backgroundColor: FColorTypes.PRIMARY_WHITE,
		border: "0.1rem solid " + FColorTypes.PRIMARY_LIGHT,
		borderRadius: "0.25rem",
		display: "flex",
		flexDirection: "row",
		columnGap: "1rem",
		alignItems: "center",
		$nest: {
			"&:hover": {
				borderColor: localTheme.mainThemeColor,
			},
		},
	});
};
export const useIconContainer = (props: FListCardProps) => {
	const { theme } = useFUITheme();
	const localTheme = props.theme ?? theme;
	return style({
		cursor: "pointer",
		$nest: {
			"&:hover": {
				borderColor: localTheme.mainThemeColor,
			},
		},
	});
};
export const useRowContentContainer = (props: FListCardProps) => {
	const { theme } = useFUITheme();
	const localTheme = props.theme ?? theme;
	return style({
		// backgroundColor: "red",
		height: "100%",
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
		justifyContent:"center",
		cursor: "pointer",
		$nest: {
			"&:hover": {
				$nest: {
					div: {
						color: localTheme.mainThemeColor,
					},
				},
			},
		},
	});
};
