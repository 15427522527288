import { FText } from "@fantaskticedtechlimited/fui-complib";

function Setting() {
	return (
		<FText
			children="This is Setting Page."
			style={{
				textAlign: "center",
                marginTop: "1rem"
			}}
		/>
	);
}

export default Setting;
