import { FColorTypes } from "@fantaskticedtechlimited/fui-colorlib";
import { FText } from "@fantaskticedtechlimited/fui-complib";
import { FFontTypes } from "@fantaskticedtechlimited/fui-fontlib";
import { useNavigate } from "react-router-dom";
import * as styles from "./styles";
import backBtn from "../../../assets/images/back-button.svg";
import useCountDown from "../../../utils/useCountDown";
function SentEmailMagicLink() {
	const navigate = useNavigate();
	const GoBackLoginPage = () => {
		navigate("/login", { replace: true });
	};
	const { currentTime} = useCountDown({
		time: 5,
		onCallback: GoBackLoginPage,
	});
	return (
		<div className={styles.loginBoxContainer}>
			<img
				src={backBtn}
				className={styles.backButton}
				onClick={GoBackLoginPage}
				alt="backBtn"
			/>
			<FText font={FFontTypes.Title()}>Magic Link</FText>
			<FText font={FFontTypes.Large_Text()} color={FColorTypes.PRIMARY_BLACK}>
				A magic link has been sent to your email
			</FText>
			<FText font={FFontTypes.Large_Text()} color={FColorTypes.PRIMARY_BLACK}>
				Please login with the magic link
			</FText>
			<FText font={FFontTypes.Text()} color={FColorTypes.PRIMARY_GREY }>
				{`You will be redirected to login page in ${currentTime} seconds`}
			</FText>
		</div>
	);
}

export default SentEmailMagicLink;
