import { HomeworkListProps } from "./types";
import * as styles from "./styles";
import HomeworkRow from "../HomeworkRow";
import { Homework } from "../../../../../assets/type/backend.type";

function HomeworkList(props: HomeworkListProps) {

	const handleMoreActionPopUpCallback = async () => {
		await props.onRefresh();
	};
	return (
		<div className={styles.homeworkList_topicListDiv}>
			{/* TopicList List looping */}
			{(props.homeworks || []).map((homework: Homework, index: number) => (
				<HomeworkRow
					key={index}
					index={index}
					homework={homework}
					onMoreActionButtonClick={() =>
						{
							alert(`Click homework ${homework.id} More Button`)
						}
					}
				/>
			))}
		</div>
	);
}

export default HomeworkList;
