import { FColorTypes } from "@fantaskticedtechlimited/fui-colorlib"
import {
	FButton,
	FNoData,
	FScrollable,
	FText,
} from "@fantaskticedtechlimited/fui-complib"
import { FButtonTypes } from "@fantaskticedtechlimited/fui-complib/lib/esm/FButton/types"
import { FFontTypes } from "@fantaskticedtechlimited/fui-fontlib"
import { Fragment, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import useCourseAPIService from "../../apiService/course"
import { CourseMember } from "../../assets/type/backend.type"
import FListCard from "../../Components/FListCard"
import { useUserSettingContext } from "../../contexts/userSettingContext"
import FScrollableContentWrapper from "../../layout/FScrollableContentWrapper"
import PageContainer from "../../layout/PageContainer"
import {
	useLoadingPageWrapperContext,
	WithLoadingPageWrapper,
} from "../../layout/WithLoadingPageWrapper"
import useMount from "../../utils/useMount"
import { useStateSafe } from "../../utils/useStateSafe"
import * as styles from "./styles"

function MyCourses() {
	const navigate = useNavigate()
	const { setLoadingStart, setLoadingEnd } = useLoadingPageWrapperContext()
	const CourseAPI = useCourseAPIService()
	const { setCourseMember } = useUserSettingContext()
	const [courseMemberList, setCourseMemberList] = useStateSafe<
		CourseMember[]
	>([])

	const handleGetMyCourses = useCallback(
		async (withLoading?: boolean) => {
			withLoading && setLoadingStart()
			try {
				const MyCoursesResult = await CourseAPI.getMyCourses({})
				if (MyCoursesResult.data.myCourses.isSuccess) {
					setCourseMemberList(
						MyCoursesResult.data.myCourses.data || [],
					)
				}
			} catch (error) {}
			withLoading && setLoadingEnd()
		},
		[CourseAPI, setCourseMemberList, setLoadingEnd, setLoadingEnd],
	)

	const handleSelectCourseMember = (value: CourseMember | null) => {
		setCourseMember(value)
		navigate("/course")
	}

	useMount(() => {
		handleGetMyCourses(true)
	})
	return (
		<PageContainer
			header={
				<div className={styles.toolbarContainer}>
					{/* Toolbar */}
					{/* Title */}
					<FText
						style={{
							flexGrow: 1,
						}}
						font={FFontTypes.Title()}
					>
						My Courses
					</FText>

					{/* New Folder button */}
					<FButton
						type={FButtonTypes.PRIMARY}
						onClick={() => {
							alert("New Courses")
						}}
						label="New Courses"
					/>

					{/* refresh button */}
					<FButton
						type={FButtonTypes.PRIMARY}
						onClick={async () => await handleGetMyCourses(true)}
						label="Refresh"
					/>
				</div>
			}
			content={
				<FScrollable>
					<FScrollableContentWrapper>
						{courseMemberList.length === 0 ? (
							<FNoData />
						) : (
							<Fragment>
								{courseMemberList.map((e, i) => {
									return (
										<FListCard
											key={i}
											index={i}
											content={
												<>
													<FText
														font={FFontTypes.Small_Text()}
														color={
															FColorTypes.PRIMARY_GREY
														}
													>{`${e.course?.subject}`}</FText>
													<FText maxRows={2}>
														{e.course?.name}
													</FText>
												</>
											}
											onClick={() => {
												handleSelectCourseMember(e)
											}}
										/>
									)
								})}
							</Fragment>
						)}
					</FScrollableContentWrapper>
				</FScrollable>
			}
		/>
	)
}

export default WithLoadingPageWrapper(MyCourses)
