import { gql } from "@apollo/client";

export const FIREBASEUSER_REGISTER_MUTATION = gql`
mutation firebaseUserRegister($firebaseUserRegisterInput:FirebaseUserRegisterInput!) {
    firebaseUserRegister(firebaseUserRegisterInput: $firebaseUserRegisterInput) {
        isSuccess
        errorMessage
        data {
            id
        }
    }
}
`;