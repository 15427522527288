import * as style from "./style";
import { PageContainerProps } from "./types";

const PageContainer = (props: PageContainerProps) => {
	return (
		<div className={style.usePageContainer(props)} style={props.style}>
			{props.header && <>{props.header}</>}
			{props.divider && <div className={style.divider} />}
			{props.content}
		</div>
	);
};
export default PageContainer;
