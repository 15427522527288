import {
	FText,
	FInputField,
	FButton,
	FPasswordInputField,
} from "@fantaskticedtechlimited/fui-complib"
import { FFontTypes } from "@fantaskticedtechlimited/fui-fontlib"
import { getAuth } from "firebase/auth"
import { useRef, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import useAuthAPIService from "../../../apiService/auth"
import firebaseAppWithConfig from "../../../config/firebaseConfig"
import useFirebaseAuthService from "../../../utils/useFirebaseAuthService"
import * as styles from "./styles"
import { FButtonTypes } from "@fantaskticedtechlimited/fui-complib/lib/esm/FButton/types"
import { ImageAsset } from "../../../assets/images"

function Register() {
	const authAPIService = useAuthAPIService()
	const firebaseAuthService = useFirebaseAuthService()
	const navigate = useNavigate()
	let [searchParams] = useSearchParams()
	const [redirectURL] = useState(searchParams.get("redirectURL") ?? "/")

	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const formRef = useRef<HTMLFormElement>(null)
	const [isLoading, setIsLoading] = useState(false)

	const handleEmailPasswordRegister = async (
		_email: string,
		_password: string,
	) => {
		// verify email and password
		if (_email === "" || _password === "") {
			return alert("Email and Password can't be empty!")
		}
		try {
			setIsLoading(true)
			let auth = getAuth(firebaseAppWithConfig)
			const { firebaseToken } =
				await firebaseAuthService.handleCreateUserWithEmailAndPassword(
					auth,
					_email,
					_password,
				)
			return await authAPIService.handleSignInSuccessRedirect(
				"/",
				firebaseToken,
				redirectURL,
			)
		} catch (error) {
			alert("Failed to signIn with Email Password")
		}
		setIsLoading(false)
	}

	return (
		<form
			className={styles.loginBoxContainer}
			onSubmit={async (e) => {
				// e.preventDefault()
				console.log("form.dispatchEvent(new Event('submit'))")
				await handleEmailPasswordRegister(email, password)
			}}
			ref={formRef}
		>
			<img
				src={ImageAsset.backBtn}
				className={styles.backButton}
				onClick={() => navigate("/login", { replace: true })}
				alt="backBtn"
			/>
			<FText font={FFontTypes.Title()}>Register</FText>
			<FInputField
				inputValue={email}
				renderInputValue={(v) => setEmail(v)}
				label="Email"
			/>
			<FPasswordInputField
				containerStyle={{
					width: "100%",
				}}
				inputValue={password}
				renderInputValue={(v) => setPassword(v)}
				placeholder="password"
			/>
			<FButton
				className={styles.loginButton}
				type={FButtonTypes.PRIMARY}
				label="Register"
				disabled={isLoading}
				onClick={() => {
					formRef.current?.dispatchEvent(
						new Event("submit", {
							cancelable: true,
							bubbles: true,
						}),
					)
				}}
			/>
		</form>
	)
}

export default Register
