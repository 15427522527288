import { TeacherListProps } from "./types";
import * as styles from "./styles";
import TeacherRow from "../TeacherRow";
import { CourseMember } from "../../../../../assets/type/backend.type";

function TeacherList(props: TeacherListProps) {

	const handleMoreActionPopUpCallback = async () => {
		await props.onRefresh();
	};
	return (
		<div className={styles.topicListDiv}>
			{/* TopicList List looping */}
			{(props.teachers || []).map((teacher: CourseMember, index: number) => (
				<TeacherRow
					key={index}
					index={index}
					teacher={teacher}
					onMoreActionButtonClick={() =>
						{
							alert(`Click teacher ${teacher.userEmail} More Button`)
						}
					}
				/>
			))}
		</div>
	);
}

export default TeacherList;
