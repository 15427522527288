import { FListCardProps } from "./types";
import * as styles from "./style";
import { FText } from "@fantaskticedtechlimited/fui-complib";
import { FColorTypes } from "@fantaskticedtechlimited/fui-colorlib";
import { FFontTypes } from "@fantaskticedtechlimited/fui-fontlib";
function FListCard(props: FListCardProps) {
	const numberFormatter = (num: number) => {
		if (num < 10) return "0" + num.toString();
		else return num.toString();
	};

	return (
		<div className={styles.useRowContainer(props)}>
			{/* Number shows on left hand side */}
			{(props.index || props.index === 0) && (
				<div style={{ minWidth: "3rem" }}>
					<FText
						font={FFontTypes.Small_Text()}
						color={FColorTypes.PRIMARY_BLACK}
					>
						{numberFormatter(props.index)}
					</FText>
				</div>
			)}

			{/* show Icon */}
			{props.icon && (
				<div
					className={styles.useIconContainer(props)}
					onClick={props.onClick && props.onClick}
				>
					{props.icon}
				</div>
			)}

			{/* Middle part, show Topic Name */}
			<div
				className={styles.useRowContentContainer(props)}
				// redirect to topic detail page on clicks
				onClick={props.onClick && props.onClick}
			>
				{props.content}
			</div>
			{
				!props.isDisableToolBar
				&&
				<div
					style={{
						minWidth: "100px",
						display: "flex",
						flexDirection: "row",
						columnGap: "0.75rem",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					{props.toolBar}
				</div>
			}

		</div>
	);
}

export default FListCard;
