import { LocaleString } from "../../assets/type/backend.type"
import { Language, LocaleCode } from "../../assets/type/frontend.type"
import { useUserSettingContext } from "../../contexts/userSettingContext"

const LocaleCodes = Object.values(LocaleCode)
const mapLanguageToLocaleStringType = (language: Language) => {
    switch (language) {
        case Language.en_US:
            return LocaleCode.en
        case Language.zh_CN:
        case Language.zh_TW:
            return LocaleCode.zh
        default:
            return LocaleCode.en

    }
}



interface GetLocaleStringOptionProps {
    language?: Language,
    // defaultLanguage:Language
}
let defaultOption: GetLocaleStringOptionProps = {
    // defaultLanguage:Language.en_US
}

const useLocaleString = () => {
    const { currentUserSetting, defaultLanguage } = useUserSettingContext()

    const extractLocaleValues = (localeString: LocaleString) => {
        if (!localeString || localeString === null) {
            return []
        }
        let keys = Object.keys(localeString).filter(e => LocaleCodes.includes(e as LocaleCode)) as LocaleCode[]
        let output: string[] = []
        keys.forEach(k => {
            let t = localeString[k]
            t && t !== undefined && output.push(t)
        })
        return output
    }

    const matchLocaleString = (localeString: LocaleString | undefined, inputValue: string) => {
        if (!localeString || localeString === null) {
            return false
        }
        const values = extractLocaleValues(localeString)
        for (let i = 0; i < values.length; i++) {
            const element = values[i];
            if (element.toLowerCase().includes(inputValue.toLowerCase())) {
                return true
            }
        }
        return false
    }
    const getLocaleString = (
        localeString: LocaleString | undefined,
        option: GetLocaleStringOptionProps = defaultOption
    ) => {
        if (!localeString) {
            return ""
        }
        // if user choose language, return choice or ""
        if (option.language) {
            // https://blog.fundebug.com/2019/08/28/javascript-nullish-coalescing/
            // ES新提案：双问号操作符
            return localeString[mapLanguageToLocaleStringType(option.language)] ?? ""
        }
        return localeString[mapLanguageToLocaleStringType(currentUserSetting.language)] ??
            localeString[mapLanguageToLocaleStringType(defaultLanguage)] ?? ""
    }
    const newLocaleString = (
        input: string,
        option: GetLocaleStringOptionProps = defaultOption
    ): LocaleString => {
        let newLocaleString: LocaleString = {}
        if (option.language) {
            newLocaleString[mapLanguageToLocaleStringType(option.language)] = input
            return newLocaleString
        }
        newLocaleString[mapLanguageToLocaleStringType(currentUserSetting.language)] = input
        return newLocaleString
    }
    const setLocaleString = (
        input: string,
        localeString?: LocaleString | undefined,
        option: GetLocaleStringOptionProps = defaultOption
    ): LocaleString => {
        let newLocaleString: LocaleString = { ...localeString }
        if (option.language) {
            newLocaleString[mapLanguageToLocaleStringType(option.language)] = input
            return newLocaleString
        }
        newLocaleString[mapLanguageToLocaleStringType(currentUserSetting.language)] = input
        return newLocaleString
    }

    const appendLocaleString = (
        input: string,
        localeString: LocaleString | undefined,
        option: GetLocaleStringOptionProps = defaultOption
    ): LocaleString => {
        let newLocaleString: LocaleString = { ...localeString }
        if (option.language) {
            newLocaleString[mapLanguageToLocaleStringType(option.language)] += input
            return newLocaleString
        }
        newLocaleString[mapLanguageToLocaleStringType(currentUserSetting.language)] += input
        return newLocaleString
    }

    const insertLocaleString = (
        input: string,
        localeString: LocaleString | undefined,
        start: number,
        end: number | null = null,
        option: GetLocaleStringOptionProps = defaultOption
    ): LocaleString => {
        let newLocaleString: LocaleString = { ...localeString }
        let _language = option.language ? option.language : currentUserSetting.language
        let oldStr = newLocaleString[mapLanguageToLocaleStringType(_language)]
        let newStr = ""
        if (end) {
            newStr = oldStr?.slice(0, start) + input + oldStr?.slice(end)
        } else {
            newStr = oldStr?.slice(0, start) + input + oldStr?.slice(start)
        }
        newLocaleString[mapLanguageToLocaleStringType(_language)] = newStr
        return newLocaleString
    }


    return {
        getLocaleString,
        newLocaleString,
        setLocaleString,
        appendLocaleString,
        insertLocaleString,
        matchLocaleString
    }
}

// interface LocaleStringGetterOptionProps{
//     language?:Language,
//     defaultLanguage:Language
// }
// let defaultOption: LocaleStringGetterOptionProps = {
//     defaultLanguage:Language.en_US
// }
// const useLocaleString = (
//     localeString:LocaleString | undefined,
//     option:LocaleStringGetterOptionProps = defaultOption
// ) => {
//     const {currentUserSetting} = useContext(GlobalContext)
//     if(!localeString){
//         return ""
//     }
//     // if user choose language, return choice or ""
//     if(option.language){
//         // https://blog.fundebug.com/2019/08/28/javascript-nullish-coalescing/
//         // ES新提案：双问号操作符
//         return localeString[mapLanguageToLocaleStringType(option.language)] ?? ""
//     }
//     return localeString[mapLanguageToLocaleStringType(currentUserSetting.language)] ?? 
//         localeString[mapLanguageToLocaleStringType(option.defaultLanguage)] ?? ""
// }

export {
    mapLanguageToLocaleStringType,
    useLocaleString
}