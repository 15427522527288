import { gql } from "@apollo/client";

export const FIREBASEUSER_LOGIN_MUTATION = gql`
    mutation firebaseUserLogin($firebaseUserLoginInput: FirebaseUserLoginInput!) {
        firebaseUserLogin(firebaseUserLoginInput: $firebaseUserLoginInput) {
            isSuccess
            errorMessage
            data
        }
    }
`;