import { style } from "typestyle";

export const loginBoxContainer = style({
	width: "400px",
	background: "white",
	boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
	padding: "32px 32px 32px 32px",
	boxSizing: "border-box",
	$nest: {
		"@media screen and (max-width: 719px)": {
			height: "100vh",
			marginTop: "0px",
			boxShadow: "none",
		},
		body: {
			backgroundColor: "white !important",
		},
	},

	// added code for flex
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
	rowGap: ".5rem",
});

export const loginButton = style({
	$nest: {
		"&:hover": {
			opacity: 0.7,
		},
	},

	// with flex
	alignSelf: "flex-end",
});

export const backButton = style({
	width: "40px",
	height: "40px",
	$nest: {
		"&:hover": {
			opacity: 0.6,
			cursor: "pointer",
		},
	},
	alignSelf: "flex-start",
});
