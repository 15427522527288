import { FText } from '@fantaskticedtechlimited/fui-complib';
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib';
import { Outlet } from 'react-router-dom'
import CourseMemberSelectDropDown from '../../Components/CourseMemberSelectDropDown';
import { useUserSettingContext } from '../../contexts/userSettingContext';
import PageContainer from '../../layout/PageContainer';

function Course() {
	const { currentUserSetting } = useUserSettingContext();
	if (!currentUserSetting.courseMember) {
		return (
			<PageContainer
				header={"Course"}
				content={
					<>
						<FText font={FFontTypes.Title()}>Select Course:</FText>
						<CourseMemberSelectDropDown />
					</>
				}
			/>
		);
	}
	return (
		<Outlet />
	);
}

export default Course