import { ReactElement } from "react";

function FScrollableContentWrapper({ children}: { children: ReactElement }) {
	return <div
        style={{
            width:"100%",
            height:"100%",
            boxSizing:"border-box",
            padding:"16px"
        }}
    >{children}</div>;
}

export default FScrollableContentWrapper;
