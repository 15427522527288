import { FLinkButton } from "@fantaskticedtechlimited/fui-complib";
import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import * as styles from "./styles";
import { MenuButtonListProps } from "./types";
import { useRouteService } from "../../../utils/useRouteService";
import { FRouteConfig } from "../../../utils/useRouteService/types";
import { FColorTypes } from "@fantaskticedtechlimited/fui-colorlib";
import { FFontTypes } from "@fantaskticedtechlimited/fui-fontlib";
import { FFontFamily } from "@fantaskticedtechlimited/fui-fontlib/lib/esm/types";

export const MenuButtonList = (props: MenuButtonListProps) => {
	const location = useLocation();
	const routeService = useRouteService();
	const checkIsSelected = (
		locationPath: string,
		itemPath: string | string[] | undefined
	) => {
		if (!itemPath) {
			return false;
		}
		if (Array.isArray(itemPath)) {
			return itemPath.includes(locationPath);
		} else {
			return locationPath === itemPath;
		}
	};

	const checkIsIncluded = (
		locationPath: string,
		itemPath: string | string[] | undefined
	) => {
		if (!itemPath) {
			return false;
		}
		if (Array.isArray(itemPath)) {
			let isInclude = false;
			itemPath.forEach((e) => {
				if (locationPath.includes(e)) {
					isInclude = true;
					return;
				}
			});
			return isInclude;
		} else {
			return locationPath.includes(itemPath);
		}
	};

	const RenderMenuButtonList = (
		pathName: string,
		routes: FRouteConfig[],
		depth: number,
		isParentPathIncluded: boolean,
		parentPath: string
	) => {
		return (
			<Fragment>
				{routes.map((r, i) => {
					const haveSubSubMenus = Array.isArray(r.routes);
					const newParentPath =
						depth === 0 ? r.path : `${parentPath}/${r.path}`;
					const isSelected = checkIsSelected(pathName, newParentPath);
					const isIncluded = checkIsIncluded(pathName, newParentPath);
					if (r.hideInMenu) return null;
					return (
						<Fragment key={i}>
							<div className={styles.SubMenuList(isParentPathIncluded, depth)}>
								<FLinkButton
									key={`${depth}_${i}`}
									className={styles.useMenuButtonStyle(isSelected)}
									containsRouteChildren={haveSubSubMenus}
									leadingIcon={{
										name: r.iconName!,
										size: props.isFooter ? "small" : undefined,
										strokeColor: isSelected
										? FColorTypes.PRIMARY_WHITE
										: FColorTypes.PRIMARY_GREY,
									}}
									actionIcon={{
										strokeColor: isSelected
										? FColorTypes.PRIMARY_WHITE
										: FColorTypes.PRIMARY_GREY,
									}}
									pathIsSelected={isSelected}
									label={r.menuName}
									pathLink={
										r.defaultRedirect ??
										routeService.extractPathLink(newParentPath)
									}
									labelClassName={styles.labelButton}
									labelStyle={{
										font: props.isFooter
											? FFontTypes.Text()
											: FFontTypes.Large_Text({
													fontFamily: FFontFamily.Poppins,
											  }),
											  color: isSelected ? FColorTypes.PRIMARY_WHITE : FColorTypes.PRIMARY_GREY,
									}}
									style={{
										// backgroundColor: isSelected ? FColorTypes.UNIPLUS_ORANGE : FColorTypes.PRIMARY_WHITE ,
										width: "100%",
										color: FColorTypes.PRIMARY_WHITE 
									}}
								/>
							</div>
							{haveSubSubMenus &&
								RenderMenuButtonList(
									pathName,
									r.routes!,
									depth + 1,
									isIncluded,
									newParentPath || ""
								)}
						</Fragment>
					);
				})}
			</Fragment>
		);
	};
	return (
		<div className={styles.MenuListDiv(props.autoExpand!)}>
			{RenderMenuButtonList(location.pathname, props.routes, 0, false, "")}
		</div>
	);
};
