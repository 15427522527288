import {
	CourseArrayOutput,
	CourseFilterInput,
	CourseMemberArrayOutput,
	CourseMemberFilterInput,
	CourseMemberOutput,
	CourseOutput,
	HomeworkArrayOutput,
	HomeworkFilterInput,
	HomeworkOutput,
} from "../../assets/type/backend.type"
import { useCustomLazyQuery } from "../../utils/useCustomLazyQuery"
import { GET_COURSE_DETAIL_BY_ID_QUERY } from "./query/getCourseDetailById.gql"
import { GET_COURSES_BY_FILTER_QUERY } from "./query/getCoursesByFilter.gql"
import { GET_MY_COURSES_QUERY } from "./query/getMyCourses.gql"

const useCourseAPIService = () => {
	const getMyCourses =
		useCustomLazyQuery<{ myCourses: CourseMemberArrayOutput }>(
			GET_MY_COURSES_QUERY,
		)

	// Course
	const getCourseDetailById = useCustomLazyQuery<
		{ courseGet: CourseOutput },
		{ courseId: string }
	>(GET_COURSE_DETAIL_BY_ID_QUERY)

	const getCoursesByFilter = useCustomLazyQuery<
		{ coursesGet: CourseArrayOutput },
		{ courseFilterInput?: CourseFilterInput }
	>(GET_COURSES_BY_FILTER_QUERY)

	// CourseMember
	const getCourseMemberDetailById = useCustomLazyQuery<
		{ courseMemberGet: CourseMemberOutput },
		{ courseMemberId: string }
	>(GET_COURSE_DETAIL_BY_ID_QUERY)

	const getCourseMembersByFilter = useCustomLazyQuery<
		{ courseMembersGet: CourseMemberArrayOutput },
		{ courseMemberFilterInput?: CourseMemberFilterInput }
	>(GET_COURSES_BY_FILTER_QUERY)

	// Homework
	const getHomeworkDetailById = useCustomLazyQuery<
		{ homeworkGet: HomeworkOutput },
		{ homeworkId: string }
	>(GET_COURSE_DETAIL_BY_ID_QUERY)

	const getHomeworksByFilter = useCustomLazyQuery<
		{ homeworksGet: HomeworkArrayOutput },
		{ homeworkFilterInput?: HomeworkFilterInput }
	>(GET_COURSES_BY_FILTER_QUERY)

	return {
		// My courses
		getMyCourses,

		// courses
		getCourseDetailById,
		getCoursesByFilter,

		// courseMembers
		getCourseMemberDetailById,
		getCourseMembersByFilter,

		// homeworks
		getHomeworkDetailById,
		getHomeworksByFilter,
	}
}

export default useCourseAPIService
