import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { JWT_TOKEN } from "../../assets/data";
import {
	AuthFirebaseLoginOutput,
	AuthFirebaseRegisterOutput,
	EventTokenOutput,
	FirebaseUserLoginInput,
	FirebaseUserRegisterInput,
	User,
} from "../../assets/type/backend.type";
import { useCustomLazyQuery } from "../../utils/useCustomLazyQuery";
import { OTPValidateInput } from "../../utils/useMFAValidateService/type";
import { FIREBASEUSER_LOGIN_MUTATION } from "./mutation/firebaseUserLogin.gql";
import { FIREBASEUSER_REGISTER_MUTATION } from "./mutation/firebaseUserRegister.gql";
import { MFA_OTP_CREATE_MUTATION } from "./mutation/mfaOTPCreate.gql";
import { GET_ME_QUERY } from "./query/getMe";

const useAuthAPIService = () => {
	const navigate = useNavigate()
	// auth
	const [firebaseUserLoginMutation] = useMutation<
		{ firebaseUserLogin: AuthFirebaseLoginOutput },
		{ firebaseUserLoginInput: FirebaseUserLoginInput }
	>(FIREBASEUSER_LOGIN_MUTATION);

	const [firebaseUserRegisterMutation] = useMutation<
		{
			firebaseUserRegister: AuthFirebaseRegisterOutput;
		},
		{ firebaseUserRegisterInput: FirebaseUserRegisterInput }
	>(FIREBASEUSER_REGISTER_MUTATION);

	const [mfaOTPCreateMutation] = useMutation<
		{
			mfaOTPCreate: EventTokenOutput;
		},
		{
			otpValidateInput?: OTPValidateInput
		}
	>(MFA_OTP_CREATE_MUTATION);

	const getMe = useCustomLazyQuery<{ me: User }>(GET_ME_QUERY);
	const handleFirebaseTokenSignUpOrSignIn = async (firebaseToken: string) => {
		let loginResult = await firebaseUserLoginMutation({
			variables: {
				firebaseUserLoginInput: {
					firebaseToken: firebaseToken,
				},
			},
		});
		if (loginResult.data?.firebaseUserLogin.isSuccess) {
			const jwtToken = loginResult.data?.firebaseUserLogin.data as string;
			await localStorage.setItem(JWT_TOKEN, jwtToken);
			const getMeApiRes = await getMe({});
			return getMeApiRes;
		} else {
			await firebaseUserRegisterMutation({
				variables: {
					firebaseUserRegisterInput: {
						firebaseToken: firebaseToken,
					},
				},
			});
			const secondLoginResult = await firebaseUserLoginMutation({
				variables: {
					firebaseUserLoginInput: {
						firebaseToken: firebaseToken,
					},
				},
			});
			await localStorage.setItem(
				JWT_TOKEN,
				secondLoginResult.data?.firebaseUserLogin.data as string
			);
			const getMeApiRes = await getMe({});
			return getMeApiRes;
		}
	};
	async function handleSignInSuccessRedirect(path:string, _oauthToken: string,_redirectURL: string) {
		navigate(`${path}?oauthToken=${_oauthToken}&redirectURL=${_redirectURL}`,{replace:true})
	}
	return {
		// auth
		getMe,
		firebaseUserLoginMutation,
		firebaseUserRegisterMutation,
		mfaOTPCreateMutation,
		handleFirebaseTokenSignUpOrSignIn,
		handleSignInSuccessRedirect
	};
};

export default useAuthAPIService;
