import { getAuth, signOut } from "firebase/auth";
import { createContext, ElementType, useContext, useState } from "react";
import { JWT_TOKEN } from "../../assets/data";
import { User } from "../../assets/type/backend.type";
import firebaseAppWithConfig from "../../config/firebaseConfig";
import { AuthContextProps } from "./type";

type WithUserAuthContext = AuthContextProps<User>

const AuthContext = createContext<WithUserAuthContext>({
	currentUser: null,
	setCurrentUser: () => {},
	isSignin: false,
	setSignin: () => {},
	signOut: () => {},
});
export const useAuthContext = () => useContext(AuthContext) 
export const WithAuthContext = (Component: ElementType) => {
	return function WithAuthContext(props: any) {
		const [currentUser, setCurrentUser] = useState<User | null>(null);
		const [isSignin, setSignin] = useState<boolean>(false);
		try {
			const auth = getAuth(firebaseAppWithConfig);
			const defaultContextValue: WithUserAuthContext = {
				currentUser: currentUser,
				setCurrentUser: setCurrentUser,
	
				isSignin,
				setSignin,
				signOut: async () => {
					signOut(auth);
					localStorage.removeItem(JWT_TOKEN)
					// localStorage.clear();
					// sessionStorage.clear();
					// await indexedDB.deleteDatabase("firebaseLocalStorageDb");
					setCurrentUser(null);
					setSignin(false);
				},
			};
			return (
				<AuthContext.Provider value={defaultContextValue}>
					<Component {...props}/>
				</AuthContext.Provider>
			);
		} catch (error) {
			console.error("AuthContext Error", error)
			return <div>AuthContext Error</div>
		}
	};
};
