import {
	createHttpLink,
	ApolloLink,
	ApolloClient,
	from,
	InMemoryCache,
	ApolloProvider,
} from "@apollo/client";
import ApolloLinkTimeout from 'apollo-link-timeout';
import { ElementType } from "react";
import { JWT_TOKEN } from "../../assets/data";
import { useMFAValidateService } from "../../utils/useMFAValidateService";
import { OTPValidateInput } from "../../utils/useMFAValidateService/type";

const timeoutLink = new ApolloLinkTimeout(300000) //30 seconds
const httpLink = createHttpLink({
	// uri: "https://fantasktic-test.herokuapp.com/graphql"
	uri:
		process.env.NODE_ENV === "production"
			? process.env.REACT_APP_BACKEND_PRODUCTION_SERVER_URL
			: process.env.REACT_APP_BACKEND_TEST_SERVER_URL,
});
const timeoutHttpLink = timeoutLink.concat(httpLink);
const authMiddleware = new ApolloLink((operation, forward) => {
	// console.log("variables", operation.variables)
	const mfaValidateService = useMFAValidateService()
	const otpValidateInput: OTPValidateInput = operation.variables.hasOwnProperty(
		"otpValidateInput"
	)
		? operation.variables.otpValidateInput
		: undefined;
	const customHeaders = operation.getContext().hasOwnProperty("headers")
		? operation.getContext().headers
		: {};
	const jwtToken = localStorage.getItem(JWT_TOKEN);

	operation.setContext({
		headers: {
			...customHeaders,
			//we can also set the authorization header
			authorization: `Bearer ${jwtToken}`,
			...mfaValidateService.ConvertOTPToHeader(otpValidateInput)
		},
	});
	return forward(operation);
});

export const WithApolloGQLContext = (Component: ElementType) => {

	return function WithApolloGQLContext(props: any) {
		try {
			const aplloClient = new ApolloClient({
				link: from([authMiddleware, timeoutHttpLink]),
				cache: new InMemoryCache({
					// addTypename:false
				}),
			});
			return (
				<ApolloProvider client={aplloClient}>
					<Component {...props} />
				</ApolloProvider>
			);
		} catch (error) {
			console.error("apolloGQLContext", error)
			return <div>ApolloGQLContext Error</div>
		}
	};
};
